import { graphql } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import ContactForm from "../components/ContactForm"
import Carousel from "react-bootstrap/Carousel"
import CarouselCaption from "react-bootstrap/CarouselCaption"
import CookieConsent from "react-cookie-consent"
import FadeIn from "react-fade-in"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/free-solid-svg-icons"
import { config } from '@fortawesome/fontawesome-svg-core';

config.autoAddCss = false;
const PageTemplate = ({ data }) => {
 const Page = data.wpgraphql.page
 console.log("page",Page)
  return (
    <Layout>
      <SEO
        title={ data.wpgraphql.page.title}
        description={Page.excerpt}
      />

      <Container className="listing-container pt-0 mt-5 ">
        <Row>
          

        
     
          
          <Col md={12} className="p-0">
            <Carousel fade={true} indicators={true} controls={true}>
              {Page.acf_standard &&
                Page.acf_standard.headerImages &&
                Page.acf_standard.headerImages.map((image,index) => (
                  <Carousel.Item key={`${index}carouselImage`}>
                   {image.headerImageHeadline && <CarouselCaption className="carousel-caption sliderWrapper">
                     
                    
                        <h1><a href={image.headerImageLink}>
                          {image.headerImageHeadline}</a>
                        </h1>
                        
                      
                    </CarouselCaption>}
                    {image.headerImage && <Img
                      className="d-block w-100"
                      style={{ height: "600px", objectFit:"contain"}}
                      sizes={image.headerImage.imageFile.childImageSharp.sizes}
                    />}
                  </Carousel.Item>
                ))}
            </Carousel>
          </Col>
        </Row>
     
        <Row className="pt-5">
          <Col md={12} id="mainContent">
            <h1> {Page.title}</h1>
            <div
              dangerouslySetInnerHTML={{ __html: Page.content }}
            />
          </Col>
          {Page.acf_download.download && Page.acf_download.download.map(download=>(
          download.downloadContent &&  <Col md={12}><a href={download.downloadContent.mediaItemUrl} download><FontAwesomeIcon icon={faDownload}/> {download.downloadContent.title}</a></Col>)
          )}
          <Col md={12}>
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default PageTemplate
export const query = graphql`
  query($id: ID!) {
    wpgraphql{
    page(id: $id ) {
      title
      content
      acf_download {
        download {
          
          downloadContent {
            staticUrl
            title
            mediaItemId
            mediaItemUrl
            modified
          }
        }
      }
       acf_standard {
        headerImages {
          headerImageHeadline
          headerImageLink
          headerImage {
            sourceUrl
            mediaItemId
            mediaItemUrl
            modified
            imageFile{
            childImageSharp {
              sizes(maxWidth: 1200) {
                ...GatsbyImageSharpSizes
              }
            }}
          }}
        }
      }
    }
   
  }
`
